import { AfterViewChecked, Component, OnInit, ViewChild } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { IonModal, ModalController, Platform } from "@ionic/angular";
import { DetailService } from "../api/services/detail.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { KontaktDetail } from "../api/models/kontakt-detail";
import { TitelService } from "../api/services/titel.service";
import { DokumenteService } from "../api/services/dokumente.service";
import { DokumentTyp } from "../util/file-overview/file-overview.component";
import { FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EmailService } from "../services/email.service";
import { SmsService } from "../services/sms.service";
import { TermineService } from "../api/services/termine.service";
import { AktortBearbeitenComponent } from "../util/aktort-bearbeiten/aktort-bearbeiten.component";
import { AuthService } from "../services/auth.service";
import { AktOrtArt, AktSparte, UserRole } from "../util/enums/global-enums";
import { DetailAktDataRetrieveResponse } from "../api/models/detail-akt-data-retrieve-response";
import {
  isAktArtTechnik,
  isKontaktAllowedForEdit,
} from "../util/global-functions/global-functions";
import { TerminBestaetigenComponent } from "../util/termin-bestaetigen/termin-bestaetigen.component";
import { TerminWeitersetzenKommentarComponent } from "../util/termin-weitersetzen-kommentar/termin-weitersetzen-kommentar.component";
import { OdataDokumentResponse } from "../api/models/odata-dokument-response";

enum TabsSelector {
  Dokumente = 1,
  Termine = 2,
  Kommentare = 3,
  Aktionen = 4,
  Umwelteinfluesse = 5,
}

export enum TerminArt {
  Kalendierung = "Kalendierung",
  Kommentar = "Kommentar",
}

@Component({
  selector: "app-details",
  templateUrl: "./details.page.html",
  styleUrls: ["./details.page.scss"],
  standalone: false,
})
export class DetailsPage implements OnInit, AfterViewChecked {
  detailData: DetailAktDataRetrieveResponse;
  ast: KontaktDetail;
  fca: KontaktDetail;
  sb2: KontaktDetail;
  vu: KontaktDetail;
  vn: KontaktDetail;
  vusb: KontaktDetail;
  vermittler: KontaktDetail;
  isDataLoaded = false;
  titel = null;
  shortSB2 = null;
  ansprechpartner: KontaktDetail = null;
  faircheckEmail;
  odataAktTermine;
  odataAktTermineFetchFailed: boolean = false;
  odataAktKommentare;
  odataAktKommentareFetchFailed: boolean = false;
  odataAktDokumente: OdataDokumentResponse;
  odataAktDokumenteFetchFailed: boolean = false;

  kundentermin;
  currentBearbeitenModal: HTMLIonModalElement;
  currentWeitersetzenModal: HTMLIonModalElement;

  selector: FormControl<TabsSelector>;
  selectorOptions = TabsSelector;

  @ViewChild("termineSort", { read: MatSort })
  public termineSort: MatSort;
  @ViewChild("kommentareMatTable", { read: MatSort })
  public kommentareSort: MatSort;

  displayedTermineColumns: string[] = [
    "Beginn",
    "KurzText",
    "Beschreibung",
    "Bemerkung",
    "Erledigt",
    "Status",
  ];
  displayedKommentareColumns: string[] = ["Beginn", "Betreff", "Bemerkung"];
  dataSourceTermineMatTable: MatTableDataSource<any>;
  dataSourceKommentareMatTable: MatTableDataSource<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private detailService: DetailService,
    private oidcSecurityService: OidcSecurityService,
    private titelService: TitelService,
    private dokumenteService: DokumenteService,
    private termineService: TermineService,
    private modalController: ModalController,
    public platform: Platform,
    public emailService: EmailService,
    public smsService: SmsService,
    public authService: AuthService,
  ) {
    this.dataSourceTermineMatTable = new MatTableDataSource([]);
    this.dataSourceKommentareMatTable = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.detailData = null;
    this.titelService
      .titelRetrieveCreate(this.getChecklistParams(this.route.snapshot))
      .subscribe((res) => {
        this.titel = res[0];
        this.shortSB2 = res[1];
      });
    this.selector = new FormControl(TabsSelector.Termine);
    this.detailService
      .detailRetrieveCreate(this.getChecklistParams(this.route.snapshot))
      .subscribe((result: DetailAktDataRetrieveResponse) => {
        this.detailData = JSON.parse(
          JSON.stringify(result),
        ) as DetailAktDataRetrieveResponse;
        this.ast = this.convertToContact(result.Contacts.AST);
        this.fca = this.convertToContact(result.Contacts.FCA);
        this.sb2 = this.convertToContact(result.Contacts.SB2);
        this.vu = this.convertToContact(result.Contacts.VU);
        this.vn = this.convertToContact(result.Contacts.VN);
        this.vusb = this.convertToContact(result.Contacts.VUSB);
        this.vermittler = this.convertToContact(result.Contacts.Vermittler);
        this.ansprechpartner = this.convertToContact(
          result.Contacts.Ansprechpartner,
        );
        this.isDataLoaded = true;
        this.fetchTermine();
        this.fetchKommentare();
        this.fetchDokuments();
        this.getEmailLink();
      });
  }

  ngAfterViewChecked() {
    this.dataSourceTermineMatTable.sort = this.termineSort;
    this.dataSourceKommentareMatTable.sort = this.kommentareSort;
  }

  openMaps() {
    const lat = encodeURI(this.detailData.Location.Geo_lat);
    const lng = encodeURI(this.detailData.Location.Geo_lng);
    const address = encodeURI(this.detailData.Location.Address);
    const geocoords = lat + "," + lng;

    if (this.platform.is("ios")) {
      window.open("maps://?q=" + geocoords, "_system");
    } else if (this.platform.is("android")) {
      window.open("geo:0,0?q=" + geocoords + "(" + address + ")", "_system");
    } else {
      window
        .open(
          "https://maps.google.com/maps?q=" + geocoords + "(" + address + ")",
          "_blank",
        )
        .focus();
    }
  }

  private getChecklistParams(snapshot: ActivatedRouteSnapshot) {
    const x = {
      akt_id: snapshot.queryParams.aktid,
      snr: snapshot.queryParams.snr,
    };

    if (this.oidcSecurityService.getToken().length) {
      return { ...x, ...{ token: this.oidcSecurityService.getToken() } };
    }

    return x;
  }

  private convertToContact(kd: KontaktDetail) {
    if (kd) {
      kd.Name = this.trim(kd.Name);
      kd.Strasse = this.trim(kd.Strasse);
      kd.Plz = this.trim(kd.Plz);
      kd.Ort = this.trim(kd.Ort);
      kd.Email = this.trim(kd.Email);
      kd.Mobile = this.trim(kd.Mobile);
      kd.Phone = this.trim(kd.Phone);
    }
    return kd;
  }

  private trim(value: string) {
    return value ? value.trim() : "";
  }

  getEmailLink() {
    let subject = "";
    subject += "Betreffend Auftrag [" + this.detailData.Title + "] ";
    subject += "Schadennr. [" + this.detailData.Incident_number + "] ";
    subject +=
      "Anspruchsteller [" + this.detailData.Contacts.Ansprechpartner.Name + "]";
    subject = encodeURI(subject);
    this.faircheckEmail =
      "mailto:" +
      this.fca.Email +
      "?subject=" +
      subject +
      "&body=Sehr%20geehrte(r)";
  }

  checkEditPermission(kontaktDetail: KontaktDetail): boolean {
    if (this.authService.isCustomer()) return false;

    return isKontaktAllowedForEdit(kontaktDetail);
  }

  fetchDokuments() {
    this.odataAktDokumente = null;
    this.dokumenteService
      .dokumenteOdataAktCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: Number(this.detailData.Id),
      })
      .subscribe({
        next: (result: OdataDokumentResponse) => {
          this.odataAktDokumente = result;
        },
        error: (error) => {
          this.odataAktDokumenteFetchFailed = true;
        },
      });
  }

  fetchTermine() {
    this.odataAktTermine = null;
    this.termineService
      .termineOdataAktCreateResponse({
        akt_id: Number(this.detailData.Id),
        token: this.oidcSecurityService.getToken(),
        art: TerminArt.Kalendierung,
      })
      .subscribe({
        next: (result) => {
          if (!result) {
            return;
          }
          this.odataAktTermine = result.body;
          this.dataSourceTermineMatTable.data = this.odataAktTermine.map(
            (item) => ({
              ...item,
              SB: item.SB?.Oid,
              Erzeuger: item.Erzeuger?.Oid,
              Beschreibung: item.WorkflowProcess?.Beschreibung,
              KurzText: item.WorkflowProcess?.KurzText,
              Erledigt: item.OK ? "Ja" : "Nein",
            }),
          );

          this.kundentermin = this.odataAktTermine
            .sort((a, b) => {
              const dateA = new Date(a.Beginn).getTime();
              const dateB = new Date(b.Beginn).getTime();
              return dateB - dateA;
            })
            .find((termin) => termin.WorkflowProcess?.KurzText == "Kundenterm");
        },
        error: (error) => {
          this.odataAktTermineFetchFailed = true;
        },
      });
  }

  fetchKommentare() {
    this.odataAktKommentare = null;
    this.termineService
      .termineOdataAktCreateResponse({
        akt_id: Number(this.detailData.Id),
        token: this.oidcSecurityService.getToken(),
        art: TerminArt.Kommentar,
      })
      .subscribe({
        next: (result) => {
          this.odataAktKommentare = result.body;
          this.dataSourceKommentareMatTable.data = this.odataAktKommentare;
        },
        error: (error) => {
          this.odataAktKommentareFetchFailed = true;
        },
      });
  }

  afterKommentarHinzufuegen = () => {
    this.fetchKommentare();
  };

  async openAktortBearbeitenModal(aktId, aktOrtArt: AktOrtArt) {
    const modal = await this.modalController.create({
      component: AktortBearbeitenComponent,
      cssClass: [],
      componentProps: {
        aktId: aktId,
        aktOrtArt: aktOrtArt,
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (role === "confirm") {
      location.reload();
    }
  }

  async openTerminBestaetigenModal(isTechnik: boolean) {
    console.log("Tried to open Termin modal");
    if (this.currentBearbeitenModal) {
      return;
    }
    console.log("Termin modal opened");
    this.currentBearbeitenModal = await this.modalController.create({
      component: TerminBestaetigenComponent,
      cssClass: ["bigger-modal"],
      backdropDismiss: false,
      componentProps: {
        aktId: this.kundentermin.AktId,
        aktTitle: this.titel,
        aktType: this.detailData.Type,
        aktStatus: this.kundentermin.akt_status,
        isTechnik: isTechnik,
      },
    });

    await this.currentBearbeitenModal.present();
    const { data, role } = await this.currentBearbeitenModal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
    if (role === "confirm") {
      location.reload();
    }
    this.currentBearbeitenModal = null;
  }

  async openTerminWeitersetzenModal() {
    if (this.currentWeitersetzenModal) {
      return;
    }
    this.currentWeitersetzenModal = await this.modalController.create({
      component: TerminWeitersetzenKommentarComponent,
      cssClass: [],
      backdropDismiss: false,
      componentProps: {
        aktId: this.kundentermin.AktId,
        aktTitle: this.titel,
      },
    });

    await this.currentWeitersetzenModal.present();
    const { data, role } = await this.currentWeitersetzenModal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
    if (role === "confirm") {
      location.reload();
    }
    this.currentWeitersetzenModal = null;
  }

  protected readonly DokumentTyp = DokumentTyp;
  protected readonly TerminArt = TerminArt;
  protected readonly UserRole = UserRole;
  protected readonly AktOrtArt = AktOrtArt;
  protected readonly isAktArtTechnik = isAktArtTechnik;
}
