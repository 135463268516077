// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-column-beginn {
  width: 160px;
  max-width: 160px;
}

.mat-column-aktbezeichnung {
  width: 160px;
  max-width: 160px;
}

.mat-column-versicherung {
  width: 160px;
  max-width: 160px;
}

.mat-column-status {
  width: 90px;
  max-width: 90px;
}

.mat-column-gutachten {
  width: 70px;
  max-width: 70px;
}

.mat-column-sb2 {
  width: 70px;
  max-width: 70px;
}

.mat-column-sb1 {
  width: 70px;
  max-width: 70px;
}

.limited-content-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.heute-faellig-icon {
  min-width: 15px;
  width: 15px !important;
}

.gutachten-checkbox-icon {
  min-width: 20px;
  width: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/util/termintabelle-terminliste/termintabelle-terminliste.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AACA;EACE,YAAA;EACA,gBAAA;AAEF;;AACA;EACE,WAAA;EACA,eAAA;AAEF;;AACA;EACE,WAAA;EACA,eAAA;AAEF;;AACA;EACE,WAAA;EACA,eAAA;AAEF;;AACA;EACE,WAAA;EACA,eAAA;AAEF;;AACA;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AACA;EACE,eAAA;EACA,sBAAA;AAEF;;AACA;EACE,eAAA;EACA,sBAAA;AAEF","sourcesContent":[".mat-column-beginn {\n  width: 160px;\n  max-width: 160px;\n}\n\n.mat-column-aktbezeichnung {\n  width: 160px;\n  max-width: 160px;\n}\n.mat-column-versicherung {\n  width: 160px;\n  max-width: 160px;\n}\n\n.mat-column-status {\n  width: 90px;\n  max-width: 90px;\n}\n\n.mat-column-gutachten {\n  width: 70px;\n  max-width: 70px;\n}\n\n.mat-column-sb2 {\n  width: 70px;\n  max-width: 70px;\n}\n\n.mat-column-sb1 {\n  width: 70px;\n  max-width: 70px;\n}\n\n.limited-content-wrap {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.heute-faellig-icon {\n  min-width: 15px;\n  width: 15px !important;\n}\n\n.gutachten-checkbox-icon {\n  min-width: 20px;\n  width: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
