import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, IonModal, ModalController } from "@ionic/angular";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { TermineService } from "../../api/services/termine.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { EmailVersendenComponent } from "../email-versenden/email-versenden.component";
import { DetailService } from "../../api/services/detail.service";
import { DetailAktDataRetrieveResponse } from "../../api/models/detail-akt-data-retrieve-response";
import { KontaktdatenAnsichtModalComponent } from "../kontaktdaten-ansicht-modal/kontaktdaten-ansicht-modal.component";
import { KontaktDetail } from "../../api/models/kontakt-detail";
import { KontaktDetailUpdate } from "../../api/models/kontakt-detail-update";
import { KontaktService } from "../../api/services/kontakt.service";
import { TerminStatus } from "../enums/global-enums";
import { isKontaktAllowedForEdit } from "../global-functions/global-functions";
import { LoveItToastService } from "../../services/love-it-toast.service";
import { MatTimepickerOption } from "@angular/material/timepicker";

@Component({
  selector: "app-termin-bestaetigen",
  templateUrl: "./termin-bestaetigen.component.html",
  styleUrls: ["./termin-bestaetigen.component.scss"],
  standalone: false,
})
export class TerminBestaetigenComponent implements OnInit {
  @Input() aktId: number;
  @Input() aktTitle: string;
  @Input() aktType: string;
  @Input() aktStatus: string;
  @Input() isTechnik: boolean;
  @Input() berichtUrgenzTerminId: number;

  @ViewChild("emailPruefenModal") emailPruefenModal: IonModal;

  terminBestaetigenFormGroup: UntypedFormGroup;
  emailPruefenFormGroup: UntypedFormGroup;
  submitted: boolean = false;
  submittedEmailPruefenModal: boolean = false;
  emailPruefenModalIsSaving: boolean = false;
  fetchingKundentermin: boolean = false;
  terminId: number = null;
  termin: any;
  details: DetailAktDataRetrieveResponse = null;
  berichtUrExists: boolean = null;

  datumUhrzeitVisibleTerminStatus: TerminStatus[] = [TerminStatus.Vereinbart];

  formControl: FormControl<Date | null>;

  customTimeOptions: MatTimepickerOption<Date>[] = this.timeOptions;

  constructor(
    private modalController: ModalController,
    private termineService: TermineService,
    private fb: FormBuilder,
    private oidcSecurityService: OidcSecurityService,
    private loveItToastService: LoveItToastService,
    private detailService: DetailService,
    private alertController: AlertController,
    private kontaktService: KontaktService,
  ) {
    const initialValue = new Date();
    initialValue.setHours(12, 30, 0);
    this.formControl = new FormControl(initialValue);
  }

  ngOnInit() {
    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    this.terminBestaetigenFormGroup = this.fb.group({
      BemerkungNeu: [null, this.bemerkungValidator()],
      BetreffNeu: [null],
      DatumNeu: [currentDate, this.dateValidator()],
      UhrzeitNeu: [now, [this.dateValidator(), this.timeValidator()]],
      KontaktDatenRecherche: [false, Validators.required],
      TerminStatus: [TerminStatus.Vereinbart, Validators.required],
    });

    this.emailPruefenFormGroup = this.fb.group({
      email: [null, [this.emailValidator()]],
    });

    this.terminBestaetigenFormGroup
      .get("TerminStatus")
      ?.valueChanges.subscribe((status) => {
        if (this.datumUhrzeitVisibleTerminStatus.includes(status)) {
          if (!this.terminBestaetigenFormGroup.get("DatumNeu").dirty) {
            this.terminBestaetigenFormGroup
              .get("DatumNeu")
              ?.setValue(currentDate);
          }

          if (!this.terminBestaetigenFormGroup.get("UhrzeitNeu").dirty) {
            this.terminBestaetigenFormGroup.get("UhrzeitNeu")?.setValue(now);
          }
        }

        this.terminBestaetigenFormGroup
          .get("BemerkungNeu")
          ?.updateValueAndValidity();
        this.terminBestaetigenFormGroup
          .get("DatumNeu")
          ?.updateValueAndValidity();
        this.terminBestaetigenFormGroup
          .get("UhrzeitNeu")
          ?.updateValueAndValidity();
      });

    this.fetchKundentermin();
    this.fetchDetails();
    if (this.berichtUrgenzTerminId) {
      this.berichtUrExists = true;
    } else {
      this.fetchBerichtur();
    }
  }

  cancelButton() {
    if (this.terminBestaetigenFormGroup.dirty) {
      this.abbrechenBestaetigenDialogAnzeigen();
    } else {
      this.modalController.dismiss(null, "cancel");
    }
  }

  cancelButtonEmailPruefenModal() {
    this.emailPruefenModal.dismiss(null, "cancel");
  }

  async submit() {
    this.submitted = true;

    if (
      !this.datumUhrzeitVisibleTerminStatus.includes(
        this.terminBestaetigenFormGroup.get("TerminStatus").value,
      )
    ) {
      this.terminBestaetigenFormGroup
        .get("DatumNeu")
        .setValue(new Date().toISOString().split("T")[0]);
      this.terminBestaetigenFormGroup.get("UhrzeitNeu").setValue(new Date());
    }

    this.terminBestaetigenFormGroup.updateValueAndValidity();
    if (this.terminBestaetigenFormGroup.invalid) {
      this.terminBestaetigenFormGroup.markAllAsTouched();
      return;
    }

    if (!this.terminId) {
      this.loveItToastService.showToastMessage(
        "Fehler beim Laden des Termins. Speichern fehlgeschlagen.",
      );
      this.fetchKundentermin();
      return;
    }
    if (!this.details) {
      this.loveItToastService.showToastMessage(
        "Fehler beim Laden des Aktes. Speichern fehlgeschlagen.",
      );
      this.fetchDetails();
      return;
    }

    if (isKontaktAllowedForEdit(this.details.Contacts.Ansprechpartner)) {
      await this.emailPruefenModal.present();
    } else {
      await this.openEmailVersendenModal();
    }
  }

  async submitEmailPruefenModal() {
    this.submittedEmailPruefenModal = true;
    this.emailPruefenFormGroup.updateValueAndValidity();
    if (this.emailPruefenFormGroup.invalid) {
      this.emailPruefenFormGroup.markAllAsTouched();
      return;
    }

    if (this.emailPruefenFormGroup.dirty) {
      this.emailPruefenModalIsSaving = true;
      let ansprechpartnerContactDetail: KontaktDetailUpdate =
        this.details.Contacts.Ansprechpartner;
      ansprechpartnerContactDetail.Email =
        this.emailPruefenFormGroup.get("email").value;
      ansprechpartnerContactDetail.token = this.oidcSecurityService.getToken();
      ansprechpartnerContactDetail.akt_id = this.aktId.toString();
      this.kontaktService
        .kontaktSaveCreate(ansprechpartnerContactDetail)
        .subscribe({
          next: async () => {
            this.detailService
              .detailRetrieveCreate({
                akt_id: this.aktId,
                token: this.oidcSecurityService.getToken(),
              })
              .subscribe(async (response: any) => {
                this.details = response;
                this.emailPruefenModalIsSaving = false;
                await this.openEmailVersendenModal();
              });
          },
          error: async () => {
            this.emailPruefenModalIsSaving = false;
            this.loveItToastService.showToastMessage(
              "Kontakt konnte nicht gespeichert werden.",
            );
          },
        });
    } else {
      await this.openEmailVersendenModal();
    }
  }

  fetchKundentermin() {
    this.fetchingKundentermin = true;
    this.terminBestaetigenFormGroup.get("BetreffNeu").disable();
    this.termineService
      .termineOdataAktKundenterminCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: this.aktId,
      })
      .subscribe((response: any) => {
        if (response.length > 1) {
          this.termin = response.find((termin) => !termin.OK);
        } else {
          this.termin = response[0];
        }
        this.terminId = this.termin?.TerminID;
        this.terminBestaetigenFormGroup
          .get("BetreffNeu")
          .setValue(this.termin?.Betreff);
        this.terminBestaetigenFormGroup.get("BetreffNeu").enable();
        this.fetchingKundentermin = false;
      });
  }

  fetchDetails() {
    this.detailService
      .detailRetrieveCreate({
        akt_id: this.aktId,
        token: this.oidcSecurityService.getToken(),
      })
      .subscribe((response: any) => {
        this.details = response;
        this.emailPruefenFormGroup
          .get("email")
          .setValue(this.details.Contacts?.Ansprechpartner?.Email);
      });
  }

  fetchBerichtur() {
    this.termineService
      .termineOdataAktBerichturCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: this.aktId,
      })
      .subscribe((response: any) => {
        this.berichtUrExists = response.length > 0;
      });
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const terminStatus: TerminStatus =
        this.terminBestaetigenFormGroup?.get("TerminStatus")?.value;

      if (terminStatus == TerminStatus.Vereinbart && !control.value) {
        return { required: true };
      }

      if (!control.value) {
        return null;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const fiveDaysAgo = new Date();
      fiveDaysAgo.setDate(today.getDate() - 5);
      fiveDaysAgo.setHours(0, 0, 0, 0);

      const maxDate = new Date(today);
      maxDate.setDate(today.getDate() + 42);

      const inputDate = new Date(control.value);
      inputDate.setHours(0, 0, 0, 0);

      if (terminStatus === TerminStatus.Vereinbart) {
        if (inputDate < fiveDaysAgo)
          return {
            customDateError:
              "Datum darf nicht mehr als fünf Tage in der Vergangenheit liegen!",
          };
      } else {
        if (inputDate < today) {
          return {
            customDateError: "Datum darf nicht in der Vergangenheit liegen!",
          };
        }
      }

      if (inputDate > maxDate) {
        return {
          customDateError:
            "Datum darf nicht mehr als 6 Wochen in der Zukunft liegen!",
        };
      }

      return null;
    };
  }

  timeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const inputTime = new Date(control.value);

      if (inputTime.getHours() === 0 && inputTime.getMinutes() === 0) {
        return {
          customTimeError: "Uhrzeit mit 00:00 ist ungültig!",
        };
      }

      return null;
    };
  }

  bemerkungValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.terminBestaetigenFormGroup) {
        return null;
      }
      const requiredForStatus: TerminStatus[] = [
        TerminStatus.NichtMoeglichPostweg,
        TerminStatus.GeraetAufPostweg,
        TerminStatus.SpaeterMoeglich,
        TerminStatus.SpaeterVNNichtErreicht,
        TerminStatus.PoststueckEingelangt,
      ];

      const status = this.terminBestaetigenFormGroup.get("TerminStatus")?.value;
      const isRequired = requiredForStatus.includes(status);

      if (isRequired && !control.value) {
        return { required: true };
      }

      return null;
    };
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValidEmail = emailRegex.test(control.value);
        if (!isValidEmail) {
          return { customError: "Ungültige E-Mail Adresse!" };
        }
      }
      return null;
    };
  }

  isControlRequired(controlName: string): boolean {
    const control = this.terminBestaetigenFormGroup.get(controlName);
    if (!control || !control.validator) {
      return false;
    }
    const validator = control.validator({} as any);
    return validator && validator["required"];
  }

  async openKontaktdatenAnsichtModal(person: KontaktDetail) {
    const modal = await this.modalController.create({
      component: KontaktdatenAnsichtModalComponent,
      cssClass: [],
      componentProps: {
        titel: "",
        enableEdit: false,
        anspruchsteller: person.Name,
        auftrag: this.details.Title,
        schadensnummer: this.details.Incident_number,
        fca: this.details.Contacts?.FCA,
        instance: person,
        akt_id: this.aktId,
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
  }

  async openEmailVersendenModal() {
    const modal = await this.modalController.create({
      component: EmailVersendenComponent,
      cssClass: ["bigger-modal"],
      backdropDismiss: false,
      componentProps: {
        aktId: this.aktId,
        aktTitle: this.aktTitle,
        terminBestaetigenFormGroup: this.terminBestaetigenFormGroup,
        terminId: this.terminId,
        details: this.details,
        kommentarBemerkungText: "",
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }

    if (role == "confirm") {
      try {
        let topModal = await this.modalController.getTop();
        while (topModal) {
          await this.modalController.dismiss(null, "confirm");
          topModal = await this.modalController.getTop();
        }
      } catch (error) {
        console.error("Error dismissing modals:", error);
      }
    }
  }

  async abbrechenBestaetigenDialogAnzeigen() {
    const alert = await this.alertController.create({
      header: "Ungespeicherte Änderungen vorhanden",
      message:
        "Wollen Sie alle Änderungen am Termin verwerfen und das Fenster schließen?",
      buttons: [
        {
          cssClass: "color-primary",
          text: "Abbrechen",
          role: "cancel",
        },
        {
          cssClass: "color-danger",
          text: "Verwerfen",
          handler: () => {
            this.modalController.dismiss(null, "cancel");
          },
        },
      ],
    });
    await alert.present();
  }

  get timeOptions() {
    const startTime = 5;
    const endTime = 22;
    const interval = 15;
    const options = [];

    for (let hour = startTime; hour <= endTime; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        if (hour === endTime && minute > 0) {
          break;
        }
        options.push({
          label: `${hour}:${minute < 10 ? "0" + minute : minute}`,
          value: new Date(2024, 0, 1, hour, minute),
        });
      }
    }
    return options;
  }

  protected readonly TerminStatus = TerminStatus;
}
