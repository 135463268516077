export enum UserRole {
  Admin = "Admin",
  BackofficeAdmin = "BOAdmin",
  Backoffice = "Backoffice",
  Regulierer = "Regulierer",
  Interessent = "InteressentInnen",
}

export enum TerminStatus {
  Vereinbart = 0,
  SpaeterVNNichtErreicht = 1,
  NichtMoeglichPostweg = 2,
  GeraetAufPostweg = 3,
  PoststueckEingelangt = 4,
  SpaeterMoeglich = 5,
}

export enum AktSparte {
  Mobil = "Mobil",
  Technik = "Technik",
}

export enum AktOrtArt {
  Schadenort = "Schadenort",
  Besichtigung = "Besichtigung",
  Sonstiger = "Sonstiger",
}

export enum TextbausteinPlatzhalter {
  Besichtigungstermin = "{Besichtigungstermin}",
  KundenprozessLink = "{KundenprozessLink}",
}

export enum MailmanagerAction {
  ASSIGN = "ASSIGN",
  PRIVATE = "PRIVATE",
  INTERN = "INTERN",
  TRASH = "TRASH",
}

export enum GeraeteKategorie {
  NichtDefiniert = "Nicht definiert",
  WeisseWare = "Weiße Ware",
  BrauneWare = "Braune Ware",
  Computer = "Computer",
  Kleinelektro = "Kleinelektro",
  Telekommunikation = "Telekommunikation",
  Elektroanlagen = "Elektroanlagen",
  Heizungen = "Heizungen",
  Sonstige = "Sonstige",
  Haushaltstechnik = "Haushaltstechnik",
  GewerbeUndIndustrieanlagen = "Gewerbe und Industrieanlagen",
  Automatisierungstechnik = "Automatisierungstechnik",
  Alarmanlagen = "Alarmanlagen",
  Energiegewinnung = "Energiegewinnung",
  Photovoltaikanlagen = "Photovoltaikanlagen",
  Beleuchtungsanlagen = "Beleuchtungsanlagen",
}

export enum JaNeinUndefiniertOption {
  KeineDefinition = "Keine Definition",
  Ja = "Ja",
  Nein = "Nein",
}

export enum TerminWorkflowProcess {
  VNUrgenz = "VNUrgenz",
  UrgenzAN = "UrgenzAN",
  RueckfrageAn = "Rückfr AN",
  RueckfrageVu = "Rückfr VU",
  QCDurchfuehrung = "QC durchfü",
  Kundentermin = "Kundenterm",
  BerichtUrgent = "Bericht ur",
  AbloeseAng = "Ablöse ang",
  BerichtVE = "Bericht ve",
}
