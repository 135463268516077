import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatTable,
  MatTableDataSource,
} from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { OidcSecurityService } from "angular-auth-oidc-client";
import { AlertController, IonicModule, ModalController } from "@ionic/angular";
import { AuthService } from "../../services/auth.service";
import { OdataAktOrtDetailResponse } from "../../api/models/odata-akt-ort-detail-response";
import { AktGeraetComponent } from "../akt-geraet/akt-geraet.component";
import { AktService } from "../../api/services/akt.service";
import { LoveItToastService } from "../../services/love-it-toast.service";
import { FairAppSimpleRequest } from "../../api/models/fair-app-simple-request";
import { UserRole } from "../enums/global-enums";
import { applyMatSortToMatDataSource } from "../global-functions/global-functions";
import { EnumService } from "../../services/enum.service";
import { OdataAktOrtResponse } from "../../api/models/odata-akt-ort-response";

@Component({
  selector: "app-akt-geraete-list",
  templateUrl: "./akt-geraete-list.component.html",
  styleUrls: ["./akt-geraete-list.component.scss"],
  standalone: false,
})
export class AktGeraeteListComponent implements OnInit, AfterViewInit {
  @Input() openCreateModalEvent!: EventEmitter<void>;
  @Input({ required: true }) aktId: number;
  @Input() disabled: boolean;
  geraete: OdataAktOrtDetailResponse[];
  @Input() aktOrte: OdataAktOrtResponse[];
  geraeteFetchFailed: boolean;

  displayedColumns: string[] = ["Bezeichnung", "Anzahl", "DeleteButtons"];

  @ViewChild("matTable", { static: true }) matTable: MatTable<any>;

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private alertController: AlertController,
    private modalController: ModalController,
    private aktService: AktService,
    private loveItToastService: LoveItToastService,
    protected authService: AuthService,
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.getAktGeraete();
    if (this.openCreateModalEvent) {
      this.openCreateModalEvent.subscribe(() => {
        this.openGeraetModal();
      });
    }
  }

  ngAfterViewInit() {
    applyMatSortToMatDataSource(this.dataSource, this.sort);
    this.sort.sortChange.subscribe(() => {
      applyMatSortToMatDataSource(this.dataSource, this.sort);
    });
  }

  getAktGeraete() {
    this.geraete = null;
    if (this.dataSource) {
      this.displayGeraete();
    }

    this.aktService
      .aktAktortdetailOdataRetrieveCreate({
        akt_id: this.aktId,
        token: this.oidcSecurityService.getToken(),
      } as FairAppSimpleRequest)
      .subscribe({
        next: (response: OdataAktOrtDetailResponse[]) => {
          this.geraete = response;
          this.dataSource = new MatTableDataSource(this.geraete);
          this.displayGeraete();
        },
        error: (errorResponse) => {
          this.geraeteFetchFailed = true;
        },
      });
  }

  displayGeraete() {
    this.dataSource.data = this.geraete || [];
  }

  onDatatableActivate(event, row: OdataAktOrtDetailResponse) {
    if (event.target.id === "delete-geraet-button") {
      return;
    }
    this.openGeraetModal(row);
  }

  async deleteGeraetButton(geraet: OdataAktOrtDetailResponse) {
    const alert = await this.alertController.create({
      header: `Das Gerät${
        geraet?.Bezeichnung ? `" ${geraet.Bezeichnung}"` : ""
      } löschen?`,
      message: "Gelöschte Geräte können nicht wiederhergestellt werden!",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-danger",
          text: "Löschen",
          handler: () => {
            this.deleteGeraet(geraet.Oid);
          },
        },
      ],
    });
    document.body.appendChild(alert);
    await alert.present();
  }

  async deleteGeraet(oid: number) {
    this.aktService
      .aktAktortdetailOdataDeleteCreate({
        token: this.oidcSecurityService.getToken(),
        akt_id: this.aktId,
        Oid: oid,
      })
      .subscribe({
        next: (response) => {
          this.getAktGeraete();
        },
        error: async (error) => {
          const message = `Fehler beim Löschen des Gerätes!`;
          this.loveItToastService.showToastMessage(message);
        },
      });
  }

  async openGeraetModal(existingGeraet: OdataAktOrtDetailResponse = null) {
    const modal = await this.modalController.create({
      component: AktGeraetComponent,
      cssClass: "bigger-modal",
      backdropDismiss: false,
      componentProps: {
        aktId: this.aktId,
        existingGeraet: existingGeraet,
        aktOrte: this.aktOrte,
        disabled: this.disabled,
      },
    });

    modal.onDidDismiss().then((data) => {
      const d = data.data;
      if (data?.role === "confirm") {
        this.getAktGeraete();
      }
    });

    await modal.present();
  }

  protected readonly UserRole = UserRole;
}
