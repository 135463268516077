/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RouteRequest } from '../models/route-request';
import { RouteResponse } from '../models/route-response';
import { RouteSaveRequest } from '../models/route-save-request';
@Injectable({
  providedIn: 'root',
})
class RoutenService extends __BaseService {
  static readonly routenDeleteCreatePath = '/routen/delete';
  static readonly routenListCreatePath = '/routen/list';
  static readonly routenSaveCreatePath = '/routen/save';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  routenDeleteCreateResponse(data: RouteRequest): __Observable<__StrictHttpResponse<RouteRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/routen/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RouteRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  routenDeleteCreate(data: RouteRequest): __Observable<RouteRequest> {
    return this.routenDeleteCreateResponse(data).pipe(
      __map(_r => _r.body as RouteRequest)
    );
  }

  /**
   * @param data undefined
   */
  routenListCreateResponse(data: RouteRequest): __Observable<__StrictHttpResponse<Array<RouteResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/routen/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RouteResponse>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  routenListCreate(data: RouteRequest): __Observable<Array<RouteResponse>> {
    return this.routenListCreateResponse(data).pipe(
      __map(_r => _r.body as Array<RouteResponse>)
    );
  }

  /**
   * @param data undefined
   */
  routenSaveCreateResponse(data: RouteSaveRequest): __Observable<__StrictHttpResponse<RouteSaveRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/routen/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RouteSaveRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  routenSaveCreate(data: RouteSaveRequest): __Observable<RouteSaveRequest> {
    return this.routenSaveCreateResponse(data).pipe(
      __map(_r => _r.body as RouteSaveRequest)
    );
  }
}

module RoutenService {
}

export { RoutenService }
