import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IonicModule, ModalController } from "@ionic/angular";
import { MatTooltip } from "@angular/material/tooltip";
import { UtilModule } from "../../util.module";
import { TextvorlageComponent } from "../../../textvorlage/textvorlage.component";

@Component({
  selector: "app-love-it-input",
  templateUrl: "./love-it-input.component.html",
  styleUrls: ["./love-it-input.component.scss"],
  standalone: false,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoveItInputComponent),
      multi: true,
    },
  ],
})
export class LoveItInputComponent implements ControlValueAccessor {
  @Input({ required: true }) formControl!: FormControl;
  @Input({ required: true }) submitted: boolean;
  @Input({ required: true }) label: string;
  @Input() suffix: string;
  @Input() type: string;
  @Output() onTextEdited: EventEmitter<string> = new EventEmitter<string>();

  value: string = "";
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  constructor() {}

  writeValue(value: string): void {
    this.value = value || "";
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInputChange(event) {
    this.onTextEdited.emit(event?.target?.value);
  }

  protected readonly Validators = Validators;
}
