import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { TextvorlageComponent } from "../../../textvorlage/textvorlage.component";

@Component({
  selector: "app-love-it-textarea-textvorlage",
  templateUrl: "./love-it-textarea-textvorlage.component.html",
  styleUrls: ["./love-it-textarea-textvorlage.component.scss"],
  standalone: false,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoveItTextareaTextvorlageComponent),
      multi: true,
    },
  ],
})
export class LoveItTextareaTextvorlageComponent
  implements OnInit, ControlValueAccessor
{
  @Input({ required: true }) formControl!: FormControl;
  @Input({ required: true }) submitted: boolean;
  @Input() textbausteinBezeichnung!: string;
  @Input() label: string;
  @Output() onTextEdited: EventEmitter<string> = new EventEmitter<string>();

  value: string = "";
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  writeValue(value: string): void {
    this.value = value || "";
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  async openTextbausteinModal(formControl: FormControl, field: string) {
    const modal = await this.modalController.create({
      component: TextvorlageComponent,
      cssClass: "textvorlageModal",
      backdropDismiss: false,
      componentProps: {
        content: formControl?.value,
        field,
      },
    });

    modal.onDidDismiss().then((data) => {
      const d = data.data;
      if (d && d.keep) {
        this.setFormControlText(formControl, d.content);
      }
    });

    await modal.present();
  }

  setFormControlText(formControl: FormControl, newValue: any) {
    this.onTextEdited.emit(newValue);

    formControl.patchValue(newValue);
    formControl.markAsDirty();
  }

  onTextAreaChange(event) {
    this.onTextEdited.emit(event?.target?.value);
  }

  protected readonly Validators = Validators;
}
