import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnumService {
  getEnumDropdownOptions(
    enumInstance: any,
    sortAlphabetically: boolean = false,
  ): Array<{ label: string; value: string }> {
    const options = Object.keys(enumInstance).map((key) => ({
      value: key,
      label: enumInstance[key],
    }));

    if (sortAlphabetically) {
      options.sort((a, b) => a.label.localeCompare(b.label));
    }

    return options;
  }
}
