import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DokumenteService } from "../../api/services/dokumente.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ActivatedRoute } from "@angular/router";
import { DownloadService } from "../../services/download.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort, MatSortModule } from "@angular/material/sort";
import { AlertController, ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { replaceFileExtensionConditionally } from "../global-functions/global-functions";
import { AuthService } from "../../services/auth.service";
import { UserRole } from "../enums/global-enums";
import { OdataDokumentResponse } from "../../api/models/odata-dokument-response";
import { readEml } from "eml-parse-js";
import { ReadedEmlJson } from "eml-parse-js/dist/interface";
import { EmailContentComponent } from "../email-content/email-content.component";
import { MailmanagerEmailResponse } from "../../api/models/mailmanager-email-response";
import { MailmanagerMailboxEmailContentResponse } from "../../api/models/mailmanager-mailbox-email-content-response";
import { MailmanagerMailboxEmailContentAttachmentResponse } from "../../api/models/mailmanager-mailbox-email-content-attachment-response";
import { LoveItToastService } from "../../services/love-it-toast.service";

export enum DokumentTyp {
  AktDokumente,
  GutachtenDokumente,
}

@Component({
  selector: "app-file-overview",
  templateUrl: "./file-overview.component.html",
  styleUrls: ["./file-overview.component.scss"],
  standalone: false,
})
export class FileOverviewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dokumente: OdataDokumentResponse[];
  @Input() dokumentTyp: DokumentTyp;
  @Input() fetchFailed: boolean;

  displayedGutachtenColumns: string[] = [
    "sort",
    "datum",
    "kategorie",
    "bezeichnung",
    "datei_name",
    "PreviewButtons",
    "DeleteButtons",
  ];
  displayedAktColumns: string[] = [
    "art",
    "datum",
    "adressattext",
    "kategorie",
    "quelle",
    "bemerkung",
  ];
  displayedAktColumnsAdmin: string[] = [
    "art",
    "datum",
    "adressattext",
    "kategorie",
    "quelle",
    "bemerkung",
    "datei_name",
  ];
  @ViewChild("matTable", { static: true }) matTable: MatTable<any>;

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort) sort: MatSort;
  sortWasChanged: boolean = false;
  uploadingSortChanges: boolean = false;

  previewPopoverIdHead: string = "preview-";
  previewImageUrl: string = "";
  previewImageDownloadFailed: boolean = false;
  previewImageDownloadSubscription: Subscription | null = null;
  defaultDokumente = [];
  notUploadedHint: string =
    "Die Datei wurde zwischengespeichert und ist noch nicht vollständig übertragen worden.";

  constructor(
    private dokumenteService: DokumenteService,
    private oidcSecurityService: OidcSecurityService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private alertController: AlertController,
    private modalController: ModalController,
    private loveItToastService: LoveItToastService,
    public downloadService: DownloadService,
    public authService: AuthService,
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    if (this.dokumente) {
      this.dataSource = new MatTableDataSource(this.dokumente);
      this.loadDokumente();
    }
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.initSorting();
      });
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dokumente && !changes.dokumente.firstChange) {
      this.loadDokumente();
    }
  }

  loadDokumente() {
    this.dataSource.data = this.dokumente || [];
    this.dataSource.data.map((entry) => {
      entry.datei_name = replaceFileExtensionConditionally(entry.datei_name);
    });
    this.dataSource.data.sort((a, b) => a.sort - b.sort);
    this.defaultDokumente = Array.from(this.dokumente || []);
  }

  initSorting() {
    if (!this.sort) {
      return;
    }
    this.dataSource.sort = this.sort;
    if (this.dokumentTyp == DokumentTyp.GutachtenDokumente) {
      this.dataSource.sort.sort({
        id: "sort",
        start: "asc",
        disableClear: false,
      });
    }
    if (this.dokumentTyp == DokumentTyp.AktDokumente) {
      this.dataSource.sort.sort({
        id: "datum",
        start: "desc",
        disableClear: false,
      });
    }
  }
  drop(event: CdkDragDrop<string>) {
    if (this.uploadingSortChanges) {
      this.loveItToastService.showToastMessage(
        "Sortierung darf nicht während eines Uploads geändert werden.",
      );
      return;
    }
    if (this.notUploadedFilesPresent()) {
      this.loveItToastService.showToastMessage(
        "Sortierung darf nicht geändert werden solange nicht alle Dateien vollständig übertragen wurden.",
      );
      return;
    }

    const previousIndex = this.dataSource.data.findIndex(
      (d) => d === event.item.data,
    );

    moveItemInArray(this.dataSource.data, previousIndex, event.currentIndex);
    this.updateDocumentsSortFields();
    this.dataSource._updateChangeSubscription();
  }

  updateDocumentsSortFields() {
    this.dataSource.data.forEach((document, index) => {
      if (document.sort != index + 1) {
        this.sortWasChanged = true;
        document.sort = index + 1;
      }
    });
  }

  resetLocalDocumentsSort() {
    this.dataSource.data = Array.from(this.defaultDokumente || []);
    this.updateDocumentsSortFields();
    this.dataSource._updateChangeSubscription();
    this.sortWasChanged = false;
  }

  async uploadSortingOfDocuments() {
    this.uploadingSortChanges = true;

    return this.dokumenteService
      .dokumenteOdataGutachtenSortallCreateResponse({
        akt_id: this.route.snapshot.queryParams.aktid,
        token: this.oidcSecurityService.getToken(),
        dokumente: this.dataSource.data.map((document) => ({
          Oid: document.oid,
          Sort: document.sort,
        })),
      })
      .subscribe(() => {
        this.sortWasChanged = false;
        this.uploadingSortChanges = false;
      });
  }

  onDatatableActivate(event, row: OdataDokumentResponse) {
    if (
      event.target.id === "delete-gutachten-dokument-button" ||
      event.target.id === "preview-gutachten-dokument-button"
    ) {
      return;
    }
    if (!row.not_uploaded) {
      this.downloadFile(row);
    }
  }

  async openEmailModal(emailFile: string, rawFileName: string) {
    const blob: Blob = this.downloadService.getDownloadedFileBlobByString(
      emailFile,
      rawFileName,
    );
    const fileName = replaceFileExtensionConditionally(
      this.downloadService.getFileNameFromPath(rawFileName),
    );

    const parsedEmlJson: ReadedEmlJson = readEml(
      await blob.text(),
    ) as ReadedEmlJson;
    const emailHeaderObject: MailmanagerEmailResponse = {
      from: parsedEmlJson?.headers?.From,
      to: parsedEmlJson?.headers?.To,
      cc: parsedEmlJson?.headers?.Cc,
      subject: parsedEmlJson?.headers?.Subject,
      date: parsedEmlJson?.date,
    } as MailmanagerEmailResponse;

    const emailContentObject: MailmanagerMailboxEmailContentResponse = {
      attachments: parsedEmlJson.attachments?.map((entry) => {
        return {
          content: entry?.data64,
          name: entry?.name,
        } as MailmanagerMailboxEmailContentAttachmentResponse;
      }),
      message: parsedEmlJson?.text ?? "",
    } as MailmanagerMailboxEmailContentResponse;

    const modal = await this.modalController.create({
      component: EmailContentComponent,
      cssClass: "bigger-modal",
      componentProps: {
        emailHeader: emailHeaderObject,
        emailContent: emailContentObject,
        emlFileBlob: blob,
        emlFileName: fileName,
      },
    });

    await modal.present();
  }

  async downloadFile(fileData: OdataDokumentResponse) {
    switch (this.dokumentTyp) {
      case DokumentTyp.AktDokumente:
        this.dokumenteService
          .dokumenteOdataAktDownloadCreateResponse({
            dokument_id: fileData.oid,
            akt_id: this.route.snapshot.queryParams.aktid,
            token: this.oidcSecurityService.getToken(),
          })
          .subscribe((response) => {
            if (this.downloadService.isEmlFile(fileData.datei_name)) {
              this.openEmailModal(
                response.body.toString(),
                fileData.datei_name,
              );
            } else {
              this.downloadService.handleDownloadedFile(
                response.body.toString(),
                fileData.datei_name,
              );
            }
          });
        break;
      case DokumentTyp.GutachtenDokumente:
        this.dokumenteService
          .dokumenteOdataGutachtenDownloadCreateResponse({
            dokument_id: fileData.oid,
            akt_id: this.route.snapshot.queryParams.aktid,
            token: this.oidcSecurityService.getToken(),
          })
          .subscribe((response) => {
            if (this.downloadService.isEmlFile(fileData.datei_name)) {
              this.openEmailModal(
                response.body.toString(),
                fileData.datei_name,
              );
            } else {
              this.downloadService.handleDownloadedFile(
                response.body.toString(),
                fileData.datei_name,
              );
            }
          });
        break;
      default:
        break;
    }
  }

  async deleteGutachtenDokumentButton(file) {
    const alert = await this.alertController.create({
      header: `Die Datei "${this.downloadService.getFileNameFromPath(
        file.datei_name,
      )}" löschen?`,
      message: "Gelöschte Dateien können nicht wiederhergestellt werden!",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-danger",
          text: "Löschen",
          handler: () => {
            this.deleteGutachtenDokument(file.oid);
          },
        },
      ],
    });
    document.body.appendChild(alert);
    await alert.present();
  }

  deleteGutachtenDokument(fileId: number) {
    this.dokumenteService
      .dokumenteOdataGutachtenDeleteCreateResponse({
        dokument_id: fileId,
        akt_id: this.route.snapshot.queryParams.aktid,
        token: this.oidcSecurityService.getToken(),
      })
      .subscribe(() => {
        location.reload();
      });
  }

  presentPreviewPopover(event, fileData) {
    this.previewImageDownloadFailed = false;
    this.previewImageUrl = "";
    if (this.previewImageDownloadSubscription) {
      this.previewImageDownloadSubscription.unsubscribe();
    }
    const popover = document.getElementById(
      `${this.previewPopoverIdHead}${fileData.oid}`,
    );
    (popover as any).present(event);
    this.previewImageDownloadSubscription = this.dokumenteService
      .dokumenteOdataGutachtenDownloadCreateResponse({
        dokument_id: fileData.oid,
        akt_id: this.route.snapshot.queryParams.aktid,
        token: this.oidcSecurityService.getToken(),
      })
      .subscribe({
        next: (response) => {
          const blob = this.downloadService.getDownloadedFileBlobByString(
            response.body.toString(),
            fileData.datei_name,
          );
          this.previewImageUrl =
            this.downloadService.getDownloadedFileUrl(blob);
        },
        error: (error) => {
          this.previewImageDownloadFailed = true;
        },
      });
  }

  onPreviewPopoverDismiss() {
    if (this.previewImageDownloadSubscription) {
      this.previewImageDownloadSubscription.unsubscribe();
    }
  }

  notUploadedFilesPresent(): boolean {
    return this.dataSource.data.some((entry) => entry.not_uploaded);
  }

  protected readonly DokumentTyp = DokumentTyp;
  protected readonly UserRole = UserRole;
}
