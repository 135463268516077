import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { updateTitleByOrginalTitle } from "../global-functions/global-functions";
import { NgxExtendedPdfViewerService } from "ngx-extended-pdf-viewer";

@Component({
  selector: "pdf-viewer-bearbeiten",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
  standalone: false,
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfSource;
  @Input() edit: boolean;

  faircheckSecondaryColor;

  constructor(
    private modalController: ModalController,
    private pdfViewerService: NgxExtendedPdfViewerService,
    private alertController: AlertController,
  ) {}

  ngOnInit() {
    this.faircheckSecondaryColor = getComputedStyle(
      document.documentElement,
    ).getPropertyValue("--ion-color-secondary");
    this.translatePdfEditorTooltips();
  }

  setInputColorById(id: string) {
    const editorInkColorElement = document.querySelector<HTMLInputElement>(id);
    editorInkColorElement.value = this.faircheckSecondaryColor;

    const inputEvent = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    editorInkColorElement.dispatchEvent(inputEvent);
  }

  updatePlaceholderById(id: string, newPlaceholder: string) {
    const element = document.querySelector(`[id="${id}"]:not(pdf-shy-button)`);
    if (element) {
      element.setAttribute("placeholder", newPlaceholder);
    }
  }

  updateInnerHtmlByDatal10nid(id: string, newInnerHtml: string) {
    const element = document.querySelector(
      `[data-l10n-id="${id}"]:not(pdf-shy-button)`,
    );
    if (element) {
      element.innerHTML = newInnerHtml;
    }
  }

  updateInnerHtmlById(
    id: string,
    newInnerHtml: string,
    selectedPartToReplace: string = "",
  ) {
    const element = document.querySelector(`[id="${id}"]:not(pdf-shy-button)`);
    if (element) {
      if (selectedPartToReplace == "") {
        element.innerHTML = newInnerHtml;
      } else {
        const currentHtml = element.innerHTML;
        const index = currentHtml.indexOf(selectedPartToReplace);
        if (index !== -1) {
          element.innerHTML =
            currentHtml.substring(0, index) +
            newInnerHtml +
            currentHtml.substring(index + selectedPartToReplace.length);
        }
      }
    }
  }

  translatePdfEditorTooltips() {
    updateTitleByOrginalTitle("Toggle Sidebar", "Seitenmenü umschalten");
    updateTitleByOrginalTitle("Show Thumbnails", "Vorschaubilder anzeigen");
    updateTitleByOrginalTitle("Find", "Suche");
    updateTitleByOrginalTitle("Find in Document", "Im Dokument suchen");
    this.updatePlaceholderById("findInput", "Im Dokument suchen...");
    updateTitleByOrginalTitle(
      "Find the previous occurrence of the phrase",
      "Finde das vorherigen Vorkommen der Phrase",
    );
    updateTitleByOrginalTitle(
      "Find the next occurrence of the phrase",
      "Finde das nächste Vorkommen der Phrase",
    );
    this.updateInnerHtmlByDatal10nid(
      "pdfjs-find-highlight-checkbox",
      "Alle hervorheben",
    );
    this.updateInnerHtmlByDatal10nid(
      "pdfjs-find-match-case-checkbox-label",
      "Groß- und Kleinschreibung beachten",
    );
    this.updateInnerHtmlByDatal10nid(
      "pdfjs-find-match-diacritics-checkbox-label",
      "Umlaute beachten",
    );
    this.updateInnerHtmlByDatal10nid(
      "pdfjs-find-entire-word-checkbox-label",
      "Suche als ganze Wörter",
    );
    updateTitleByOrginalTitle("Go to First Page", "Springe zur ersten Seite");
    updateTitleByOrginalTitle("Previous Page", "Vorherige Seite");
    updateTitleByOrginalTitle("Page", "Seite");
    this.updateInnerHtmlById("numPages", "von", "of");
    updateTitleByOrginalTitle("Next Page", "Nächste Seite");
    updateTitleByOrginalTitle("Go to Last Page", "Springe zur letzten Seite");
    updateTitleByOrginalTitle("Zoom Out", "Ansicht verkleinern");
    updateTitleByOrginalTitle("Zoom In", "Ansicht vergrößern");
    this.updateInnerHtmlById("autoOption", "Automatische Größe");
    this.updateInnerHtmlById("pageActualOption", "Tatsächliche Größe");
    this.updateInnerHtmlById("pageFitOption", "Passende Größe");
    this.updateInnerHtmlById("pageWidthOption", "Passende Breite");
    updateTitleByOrginalTitle(
      "Switch to Presentation Mode",
      "In den Präsentationsmodus wechseln",
    );
    updateTitleByOrginalTitle(
      "Switch to Presentation Mode",
      "In den Präsentationsmodus wechseln",
    );
    updateTitleByOrginalTitle("Rotate Clockwise", "Drehen im Uhrzeigersinn");
    updateTitleByOrginalTitle(
      "Rotate Anti-Clockwise",
      "Drehen gegen den Uhrzeigersinn",
    );
    updateTitleByOrginalTitle("Draw", "Zeichnen");

    const thumbPageTitles = document.querySelectorAll('[title*="Page"]');
    thumbPageTitles.forEach((elem) => {
      const currentTitle = elem.getAttribute("title");
      const index = currentTitle.indexOf("Page");
      const replaceString = "Seite ";
      if (index !== -1) {
        elem.setAttribute(
          "title",
          currentTitle.substring(0, index) +
            replaceString +
            currentTitle.substring(index + replaceString.length),
        );
      }
    });
  }

  async addImage(
    urlOrDataUrl,
    left,
    bottom,
    right,
    top,
    rotation,
  ): Promise<void> {
    const stampAnnotation: any = {
      annotationType: 13,
      bitmapUrl: urlOrDataUrl,
      rect: [200, 450, 200 + right, 450 + top],
      rotation: rotation,
    };

    this.pdfViewerService.addEditorAnnotation(stampAnnotation);
    this.pdfViewerService.switchAnnotationEdtorMode(13);
  }

  cancelPdfModal() {
    this.modalController.dismiss(null, "cancel");
  }

  confirmPdfModal = async () => {
    const alert = await this.alertController.create({
      header: "Änderungen speichern?",
      message:
        "Gespeicherte Änderungen können nicht mehr rückgängig gemacht werden.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-secondary",
          text: "Speichern",
          role: "confirm",
          handler: async () => {
            await this.modalController.dismiss(
              await this.pdfViewerService.getCurrentDocumentAsBlob(),
              "confirm",
            );
          },
        },
      ],
    });

    alert.present();
  };
}
