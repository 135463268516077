import { Component, Input, OnInit } from "@angular/core";
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AlertController, ModalController } from "@ionic/angular";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { LoveItToastService } from "../../services/love-it-toast.service";
import { AktService } from "../../api/services/akt.service";
import { OdataAktOrtDetailPatchPost } from "../../api/models/odata-akt-ort-detail-patch-post";
import { OdataAktOrtDetailResponse } from "../../api/models/odata-akt-ort-detail-response";
import {
  GeraeteKategorie,
  JaNeinUndefiniertOption,
} from "../enums/global-enums";
import { EnumService } from "../../services/enum.service";
import { OdataAktOrtResponse } from "../../api/models/odata-akt-ort-response";

@Component({
  selector: "app-akt-geraet",
  templateUrl: "./akt-geraet.component.html",
  styleUrls: ["./akt-geraet.component.scss"],
  standalone: false,
})
export class AktGeraetComponent implements OnInit {
  @Input({ required: true }) aktId: number;
  @Input({ required: false }) existingGeraet: OdataAktOrtDetailResponse;
  @Input({ required: true }) aktOrte: OdataAktOrtResponse[];
  @Input() disabled: boolean;

  isSaving: boolean = false;
  submitted: boolean = false;

  geraetFormModel: { [key in keyof OdataAktOrtDetailResponse]?: FormControl } =
    {
      Anzahl: new FormControl(null, Validators.required),
      BauJahr: new FormControl(null),
      BauMonat: new FormControl(null, Validators.required),
      Begruendung: new FormControl(null),
      Bezeichnung: new FormControl(null, Validators.required),
      Blitzschlag: new FormControl(
        JaNeinUndefiniertOption.KeineDefinition,
        Validators.required,
      ),
      Hersteller: new FormControl(null),
      Kategorie: new FormControl(
        GeraeteKategorie.NichtDefiniert,
        Validators.required,
      ),
      ModelTyp: new FormControl(null),
      Nummer: new FormControl(null),
      NutzdauerMittel: new FormControl(null, Validators.required),
      Oid: new FormControl(null),
      OrtId: new FormControl(null, Validators.required),
      RechnungKausal: new FormControl(
        JaNeinUndefiniertOption.KeineDefinition,
        Validators.required,
      ),
      RechnungVon: new FormControl(null),
      ReparaturInklAZ: new FormControl(null, Validators.required),
      SchadenberechnungsbasisJahr: new FormControl(null, Validators.required),
      SchadenberechnungsbasisMonat: new FormControl(null, Validators.required),
      Seriennummer: new FormControl(null),
      WiederbeschaffungsWert: new FormControl(null, Validators.required),
      WiederbeschaffungsWertInklAZ: new FormControl(null, Validators.required),
      WiederherstellungLtRG: new FormControl(null, Validators.required),
      Zeitwert: new FormControl(null, Validators.required),
      ExklusiveArbeitszeit: new FormControl(
        { value: false, disabled: true },
        Validators.required,
      ),
      VerbesserungPruefbar: new FormControl(
        { value: true, disabled: true },
        Validators.required,
      ),
    };
  geraetFormGroup: FormGroup<{
    [key in keyof OdataAktOrtDetailResponse]?: FormControl;
  }> = new FormGroup(this.geraetFormModel);

  geraeteKategorieOptions: Array<{ value: string; label: string }> =
    this.enumService.getEnumDropdownOptions(GeraeteKategorie, true);
  geraeteJaNeinUndefiniertOptions: Array<{ value: string; label: string }> =
    this.enumService.getEnumDropdownOptions(JaNeinUndefiniertOption);

  get invertedCheckboxValue(): boolean {
    return !this.geraetFormGroup.controls.VerbesserungPruefbar.value;
  }

  set invertedCheckboxValue(value: boolean) {
    this.geraetFormGroup.controls.VerbesserungPruefbar.setValue(!value);
  }

  constructor(
    private modalController: ModalController,
    private oidcSecurityService: OidcSecurityService,
    private loveItToastService: LoveItToastService,
    private aktService: AktService,
    private alertController: AlertController,
    protected enumService: EnumService,
  ) {}

  ngOnInit() {
    if (this.existingGeraet) {
      this.geraetFormGroup.patchValue(this.existingGeraet);
    }
    if (this.disabled) {
      this.geraetFormGroup.disable();
    }
  }

  createGeraet() {
    return new Promise<void>((resolve, reject) => {
      this.aktService
        .aktAktortdetailOdataCreateCreate({
          token: this.oidcSecurityService.getToken(),
          akt_id: this.aktId,
          akt_ort_details: this.getCreateUpdateGeraetParams(),
        } as OdataAktOrtDetailPatchPost)
        .subscribe({
          next: (response) => {
            resolve();
          },
          error: (error) => {
            const message = `Fehler beim Erstellen des Gerätes!`;
            reject(new Error(message));
          },
        });
    });
  }

  updateGeraet() {
    return new Promise<void>((resolve, reject) => {
      this.aktService
        .aktAktortdetailOdataPatchCreate({
          token: this.oidcSecurityService.getToken(),
          akt_id: this.aktId,
          akt_ort_details: this.getCreateUpdateGeraetParams(),
        } as OdataAktOrtDetailPatchPost)
        .subscribe({
          next: (response) => {
            resolve();
          },
          error: (error) => {
            const message = `Fehler beim Speichern des Gerätes!`;
            reject(new Error(message));
          },
        });
    });
  }

  getCreateUpdateGeraetParams(): OdataAktOrtDetailResponse {
    return this.geraetFormGroup.getRawValue() as OdataAktOrtDetailResponse;
  }

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }

  submit() {
    this.submitted = true;
    if (this.geraetFormGroup.invalid) {
      this.geraetFormGroup.markAllAsTouched();
      return;
    }
    this.isSaving = true;

    let currentPromise: Promise<void> = null;
    if (
      this.existingGeraet &&
      this.existingGeraet?.Oid === this.geraetFormGroup.get("Oid").value
    ) {
      currentPromise = this.updateGeraet();
    } else {
      currentPromise = this.createGeraet();
    }

    currentPromise
      .then(() => {
        this.isSaving = false;
        this.modalController.dismiss(null, "confirm");
      })
      .catch(async (error: Error) => {
        this.isSaving = false;
        this.loveItToastService.showToastMessage(error.message);
      });
  }

  async zeitwertBerechnen() {
    const errors: string[] = [];

    const validateFormField = (value: number, message: string) => {
      if (value <= 0) errors.push(message);
    };

    const {
      WiederbeschaffungsWert,
      NutzdauerMittel,
      SchadenberechnungsbasisJahr,
      SchadenberechnungsbasisMonat,
      BauJahr,
      BauMonat,
      Zeitwert,
    } = this.geraetFormGroup.controls;

    validateFormField(
      WiederbeschaffungsWert.value,
      "Wiederbeschaffungswert muss größer 0 sein!",
    );
    validateFormField(
      NutzdauerMittel.value,
      "Mittlere Nutzungsdauer muss größer 0 sein!",
    );
    validateFormField(
      SchadenberechnungsbasisJahr.value,
      "Schadensberechnungsdatum Jahr muss größer 0 sein!",
    );
    validateFormField(
      SchadenberechnungsbasisMonat.value,
      "Schadensberechnungsdatum Monat muss größer 0 sein!",
    );

    let checkedBaujahr: number | undefined;

    if (!BauJahr.value) {
      errors.push("Baujahr Jahr muss angegeben sein!");
    } else {
      const match = BauJahr.value.match(/\d{4}/);
      if (!match) {
        errors.push("Baujahr Jahr muss eine 4-stellige Zahl sein!");
      } else {
        checkedBaujahr = parseInt(match[0]);
        if (checkedBaujahr === undefined) {
          errors.push(`Baujahr Jahr ungültig!`);
        }
        const currentYear = new Date().getFullYear();
        if (checkedBaujahr < 1900 || checkedBaujahr > currentYear) {
          errors.push(
            `Baujahr Jahr ist außerhalb des gültigen Bereiches! (1900 - ${currentYear})`,
          );
        }
      }
    }

    if (BauMonat.value < 1 || BauMonat.value > 12) {
      errors.push("Baujahr Monat muss größer 0 und kleiner gleich 12 sein!");
    }

    if (errors.length > 0) {
      const alert = await this.alertController.create({
        header: "Fehler bei der Zeitwertberechnung",
        message: `<ion-text>${errors.join("<br>")}</ion-text>`,
        buttons: [
          {
            cssClass: "color-danger",
            text: "Verstanden",
            role: "cancel",
          },
        ],
      });

      await alert.present();
      return;
    }

    const calculatedZeitwert =
      (WiederbeschaffungsWert.value / NutzdauerMittel.value) *
      (NutzdauerMittel.value -
        (SchadenberechnungsbasisJahr.value - checkedBaujahr) * 12 -
        (12 - BauMonat.value - SchadenberechnungsbasisMonat.value));

    Zeitwert.setValue(calculatedZeitwert);

    const alert = await this.alertController.create({
      header: "Zeitwert berechnet",
      message: `Der Zeitwert wurde auf ${calculatedZeitwert}€ berechnet.`,
      buttons: [
        {
          cssClass: "color-success",
          text: "Verstanden",
          role: "cancel",
        },
      ],
    });

    await alert.present();
    return;
  }
}
