import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  applyMatSortToMatDataSource,
  getLowerCaseInputValue,
  getRelevantAktDate,
  getStringFilterConditionForDatatable,
  isAktArtTechnik,
} from "../global-functions/global-functions";
import { OdataTermineResponse } from "../../api/models/odata-termine-response";
import { UserRole } from "../enums/global-enums";
import { AuthService } from "../../services/auth.service";
import { IonModal, ModalController } from "@ionic/angular";
import { BackService } from "../../services/back.service";
import { Router } from "@angular/router";
import { TerminBestaetigenComponent } from "../termin-bestaetigen/termin-bestaetigen.component";
import { TerminWeitersetzenKommentarComponent } from "../termin-weitersetzen-kommentar/termin-weitersetzen-kommentar.component";

@Component({
  selector: "app-termintabelle-terminliste",
  templateUrl: "./termintabelle-terminliste.component.html",
  styleUrls: ["./termintabelle-terminliste.component.scss"],
  standalone: false,
})
export class TermintabelleTerminlisteComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() termine;
  @Input() backPath;
  @Input() excludeColumns: string[];
  @Output() reloadData: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(IonModal) modal: IonModal;
  selectedTermin: OdataTermineResponse;
  currentBearbeitenModal: HTMLIonModalElement;
  currentWeitersetzenModal: HTMLIonModalElement;

  filterFormGroupTermineListForDatatable: FormGroup;
  filteredTermineListForDatatable;

  displayedColumnsAdmin: string[] = [
    "gutachten",
    "beginn",
    "aktbezeichnung",
    "sb2",
    "versicherung",
    "vn",
    "plz",
    "ort",
    "aktart",
    "bemerkung",
    "sb1",
    "status",
    "zusatzart",
  ];
  displayedColumns: string[] = [
    "gutachten",
    "beginn",
    "aktbezeichnung",
    "vn",
    "plz",
    "ort",
    "aktart",
    "bemerkung",
    "status",
    "zusatzart",
  ];
  dataSourceTermineMatTable: MatTableDataSource<any>;
  today = new Date();

  constructor(
    private fb: FormBuilder,
    private backService: BackService,
    private router: Router,
    private modalController: ModalController,
    public authService: AuthService,
  ) {
    this.dataSourceTermineMatTable = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.displayedColumnsAdmin = this.displayedColumnsAdmin.filter(
      (column) => !this.excludeColumns?.includes(column),
    );
    this.displayedColumns = this.displayedColumns.filter(
      (column) => !this.excludeColumns?.includes(column),
    );
    this.filterFormGroupTermineListForDatatable = this.fb.group({
      beginn: [""],
      aktbezeichnung: [""],
      sb2: [""],
      versicherung: [""],
      vn: [""],
      plz: [""],
      ort: [""],
      aktart: [""],
      bemerkung: [""],
      sb1: [""],
      status: [""],
      zusatzart: [""],
    });

    this.filterFormGroupTermineListForDatatable.valueChanges.subscribe(() => {
      this.updateDataForDatatable();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.termine) {
      this.loadTermine();
      if (this.filterFormGroupTermineListForDatatable) {
        this.updateDataForDatatable();
      }
    }
  }

  loadTermine() {
    this.dataSourceTermineMatTable.data = this.termine || [];
  }

  ngAfterViewInit() {
    applyMatSortToMatDataSource(this.dataSourceTermineMatTable, this.sort);
    this.sort.sortChange.subscribe(() => {
      applyMatSortToMatDataSource(this.dataSourceTermineMatTable, this.sort);
    });
  }

  updateDataForDatatable() {
    const beginn = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("beginn").value,
    );

    const aktbezeichnung = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("aktbezeichnung").value,
    );

    const sb2 = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("sb2").value,
    );

    const versicherung = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("versicherung").value,
    );

    const vn = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("vn").value,
    );

    const plz = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("plz").value,
    );

    const ort = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("ort").value,
    );

    const aktart = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("aktart").value,
    );

    const bemerkung = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("bemerkung").value,
    );

    const sb1 = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("sb1").value,
    );

    const status = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("status").value,
    );

    const zusatzart = getLowerCaseInputValue(
      this.filterFormGroupTermineListForDatatable.get("zusatzart").value,
    );

    this.filteredTermineListForDatatable = this.termine.filter(
      (termin) =>
        ((new Date(termin.beginn).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }) !== null &&
          beginn !== null &&
          new Date(termin.beginn)
            .toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .indexOf(beginn) !== -1) ||
          !beginn) &&
        getStringFilterConditionForDatatable(
          termin.aktbezeichnung,
          aktbezeichnung,
        ) &&
        getStringFilterConditionForDatatable(termin.sb2, sb2) &&
        getStringFilterConditionForDatatable(
          termin.versicherung,
          versicherung,
        ) &&
        getStringFilterConditionForDatatable(termin.vn, vn) &&
        getStringFilterConditionForDatatable(termin.plz, plz) &&
        getStringFilterConditionForDatatable(termin.ort, ort) &&
        getStringFilterConditionForDatatable(termin.aktart, aktart) &&
        getStringFilterConditionForDatatable(termin.bemerkung, bemerkung) &&
        getStringFilterConditionForDatatable(termin.sb1, sb1) &&
        getStringFilterConditionForDatatable(termin.status, status) &&
        getStringFilterConditionForDatatable(termin.zusatzart, zusatzart),
    );

    this.dataSourceTermineMatTable.data = this.filteredTermineListForDatatable;
  }

  getAktStatusClass(termin: OdataTermineResponse) {
    return `status_${termin.akt_sub_status} ${this.checkKundenterminHeute(
      this.getRelevantDate(termin),
    )}`;
  }

  checkKundenterminHeute(datum) {
    const today = this.today;
    const nd = new Date(datum);
    if (
      nd.getFullYear() === today.getFullYear() &&
      nd.getMonth() === today.getMonth() &&
      nd.getDate() === today.getDate()
    ) {
      return "kundentermin_heute";
    } else {
      return "";
    }
  }

  getRelevantDate(termin: OdataTermineResponse) {
    return getRelevantAktDate(
      termin?.aktart,
      termin?.akt_meeting_customer_date,
      termin?.akt_auftrag_fa_date,
    );
  }

  openTerminModal(selectedTermin) {
    this.selectedTermin = selectedTermin;
    this.modal.present();
  }

  closeTerminModal() {
    this.modal.dismiss(null, "cancel");
  }

  retrieveAktQueryParams(termin) {
    return { aktid: termin.akt_id, snr: termin.schadennummer };
  }

  openAktOfSelectedTermin(event) {
    const queryParamsAkt = this.retrieveAktQueryParams(this.selectedTermin);
    this.backService.backPath = this.backPath;
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/details"], { queryParams: queryParamsAkt }),
    );
    if (event.ctrlKey || event.metaKey) {
      window.open(url, "_blank");
    } else {
      this.router.navigate(["/details"], { queryParams: queryParamsAkt });
      this.modal.dismiss(null, "cancel");
    }
  }

  async openTerminBestaetigenModal(isTechnik: boolean) {
    console.log("Tried to open Termin modal");
    if (this.currentBearbeitenModal) {
      return;
    }
    console.log("Termin modal opened");
    this.currentBearbeitenModal = await this.modalController.create({
      component: TerminBestaetigenComponent,
      cssClass: ["bigger-modal"],
      backdropDismiss: false,
      componentProps: {
        aktId: this.selectedTermin.akt_id,
        aktTitle: this.selectedTermin.aktbezeichnung,
        aktType: this.selectedTermin.aktart,
        aktStatus: this.selectedTermin.akt_status,
        isTechnik: isTechnik,
      },
    });

    await this.currentBearbeitenModal.present();
    const { data, role } = await this.currentBearbeitenModal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
    if (role === "confirm") {
      this.reloadData.emit();
      this.modal.dismiss(null, "cancel");
    }
    this.currentBearbeitenModal = null;
  }

  async openTerminWeitersetzenModal() {
    if (this.currentWeitersetzenModal) {
      return;
    }
    this.currentWeitersetzenModal = await this.modalController.create({
      component: TerminWeitersetzenKommentarComponent,
      cssClass: [],
      backdropDismiss: false,
      componentProps: {
        aktId: this.selectedTermin.akt_id,
        aktTitle: this.selectedTermin.aktbezeichnung,
      },
    });

    await this.currentWeitersetzenModal.present();
    const { data, role } = await this.currentWeitersetzenModal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
    this.currentWeitersetzenModal = null;
  }

  protected readonly UserRole = UserRole;
  protected readonly isAktArtTechnik = isAktArtTechnik;
}
