import { Component, Input, OnInit } from "@angular/core";
import { isMobile } from "../global-functions/global-functions";
import { Platform } from "@ionic/angular";

type HazardMapCoordinates = { latitude: number; longitude: number };
enum HazardTypeEnum {
  WIND = "cwindt:",
  ERDBEBEN = "cbeben",
  ERDRUTSCH = "crutschungen:",
  HOCHWASSER = "chwrz:",
  BLITZ = "cblitzt:",
  SCHNEELAST = "cschneelast:",
}

@Component({
  selector: "app-hazard-overview",
  templateUrl: "./hazard-overview.component.html",
  styleUrls: ["./hazard-overview.component.scss"],
  standalone: false,
})
export class HazardOverviewComponent {
  @Input() date: Date;
  @Input() coordinates: HazardMapCoordinates;
  @Input() zoomLevel: number;

  hazardTypesWithDataOnDate: HazardTypeEnum[] = [
    HazardTypeEnum.WIND,
    HazardTypeEnum.BLITZ,
  ];

  constructor(protected platform: Platform) {}

  getFormattedDate(date: Date): string {
    date = new Date(date);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}${month}${day}`;
  }

  hazardMapLink(hazardType: HazardTypeEnum): string {
    let hazardInformation: string = hazardType;
    if (this.hazardTypesWithDataOnDate.includes(hazardType)) {
      const formattedDate = this.getFormattedDate(this.date);
      hazardInformation += formattedDate;
    }
    return `https://hora.gv.at/#/${hazardInformation}/bgrau/a-/@${this.coordinates.latitude},${this.coordinates.longitude},${this.zoomLevel}z/x${this.coordinates.latitude},${this.coordinates.longitude},${this.zoomLevel}z`;
  }

  protected readonly HazardTypeEnum = HazardTypeEnum;
  protected readonly isMobile = isMobile;
}
