import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Textvorlagen } from "../../api/models/textvorlagen";
import { TextvorlagenService } from "../../api/services/textvorlagen.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { MatSort } from "@angular/material/sort";
import { IonSelect, Platform } from "@ionic/angular";
import { isMobile } from "../global-functions/global-functions";

@Component({
  selector: "app-textvorlage-quick-select",
  templateUrl: "./textvorlage-quick-select.component.html",
  styleUrls: ["./textvorlage-quick-select.component.scss"],
  standalone: false,
})
export class TextvorlageQuickSelectComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) textbausteinBezeichnung: string;
  @Input() zuordnung: string;
  @Input() disabled: boolean;
  @Output() textbausteinInhaltOnSelect: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChild("select", { read: IonSelect })
  public select: IonSelect;
  dropdownOptions: { value: string; label: string }[] = [];
  isMobile: boolean = false;

  fieldId = "";

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.updateIsMobile();
  }

  constructor(
    private textvorlagenService: TextvorlagenService,
    private oidcSecurityService: OidcSecurityService,
    private platform: Platform,
  ) {}

  ngOnInit() {
    this.fieldId = `textbaustein-${this.textbausteinBezeichnung}`;
    this.updateIsMobile();
  }

  ngAfterViewInit() {
    const el = document.querySelector(`#${this.fieldId}`)?.shadowRoot;
    if (el) {
      const style = document.createElement("style");
      style.textContent = `.select-wrapper { justify-content: center !important; }`;
      el.appendChild(style);
    }
  }

  updateIsMobile() {
    this.isMobile = isMobile(this.platform);
  }

  loadDropdownOptions() {
    this.select.value = null;
    this.dropdownOptions = [];
    this.dropdownOptions.push({
      label: "Lade Textbausteine...",
      value: null,
    });
    this.textvorlagenService
      .textvorlagenList({
        benutzer: this.oidcSecurityService.getPayloadFromIdToken().sb,
        kategorie: this.textbausteinBezeichnung.toLowerCase(),
      })
      .subscribe((response: Textvorlagen[]) => {
        this.dropdownOptions = [];
        let textvorlagen: Textvorlagen[];
        textvorlagen = response.sort((a, b) => {
          const nameA = a.name?.toLowerCase() ?? "";
          const nameB = b.name?.toLowerCase() ?? "";

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        if (this.zuordnung) {
          textvorlagen = textvorlagen.filter(
            (vorlage) =>
              vorlage.zuordnung?.toLowerCase() ===
                this.zuordnung?.toLowerCase() || !vorlage.zuordnung,
          );
        }

        this.dropdownOptions = textvorlagen.map((textvorlage: Textvorlagen) => {
          return {
            value: textvorlage.inhalt,
            label: `${textvorlage.name} - ${textvorlage.inhalt}`,
          };
        });

        if (this.dropdownOptions.length == 0) {
          this.dropdownOptions.push({
            label: "Keine Textbausteine gefunden.",
            value: null,
          });
        }
      });
  }

  onSelectDropdown(inhalt: string) {
    this.textbausteinInhaltOnSelect.emit(inhalt);
  }
}
